import React from 'react';
import './terms.scss';

export default function Terms({ setTOS }) {
    var var_terms_items = require("./terms.json");
    const terms_items = var_terms_items.items;
    function close_terms() {
        setTOS(false);
    }

    return (

        <section className='menu-terms flexcol center fixed'>
            <div className='title'>Terms of Service</div>

            <div className="faq_content">
                {terms_items.map((x, index) => {
                    return (
                        <div key={index}>
                            <h2 className="faq_question">{x.question}</h2>
                            <p className="faq_answer">{x.answer}</p>
                        </div>
                    )
                })}
            </div>

            <div className='button w100' onClick={close_terms}>I Accept</div>

        </section>
    )
}
