import React, { Fragment, useEffect, useRef } from "react";
import "./sound.scss";

import logo_speaker from './img/speaker.svg';
import logo_speaker_off from './img/speaker-off.svg';

export default function Sound({ Audio, set_Audio }) {
    // on-load only
    // storing boolean in localStorage
    const audioRef = useRef(Audio);
    useEffect(() => {
        const storedSound = JSON.parse(localStorage.getItem("sound"));
        if (storedSound === null) {
            localStorage.setItem("sound", true);
            set_Audio(true);
        } else if (storedSound !== audioRef.current) {
            set_Audio(() => storedSound);
        }
        // Update the reference after setting the state
        audioRef.current = storedSound;
    }, [set_Audio]);

    // on-change only
    useEffect(() => {
        localStorage.setItem("sound", Audio);
    }, [Audio]);

    return (
        <Fragment>
            {Audio
                ?
                <img src={logo_speaker} className="img_sound" alt="logo" onClick={() => set_Audio(false)} />
                :
                <img src={logo_speaker_off} className="img_sound" alt="logo" onClick={() => set_Audio(true)} />
            }
        </Fragment>
    );

}