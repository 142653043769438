const django_api = 'https://be.adeliethrone.com:8080/'
const django_api_autho = 'https://be.adeliethrone.com:8080/autho/'
const django_api_game = 'https://be.adeliethrone.com:8080/game/'
const reactjs_api = 'https://be.adeliethrone.com:8080/'
const django_ws_play = 'wss://be.adeliethrone.com:8080/play/'

// const django_api = 'http://127.0.0.1:8000/'
// const django_api_autho = 'http://127.0.0.1:8000/autho/'
// const django_api_game = 'http://127.0.0.1:8000/game/'
// const reactjs_api = 'http://127.0.0.1:8000/'
// const django_ws_play = 'ws://127.0.0.1:8000/play/'


const timer_delay = 100


export {
    django_api,
    django_api_autho,
    django_api_game,
    reactjs_api,
    timer_delay,
    django_ws_play
}