
import axios from "axios";
import { django_api_autho } from '../../env.js'

export async function authenticate() {
    var Resp_data = {};
    const api_url = django_api_autho + 'log/'
    const api_data = {}
    const api_header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    }

    try {
        await axios.post(
            api_url,
            api_data,
            { headers: api_header, withCredentials: true, responseType: 'json' },
        )
            .then((response) => {
                Resp_data['status'] = response.status
                Resp_data['message'] = response.data.message
            })
            .catch(({ response }) => {
                Resp_data['status'] = response.status
            })
    }
    finally {
        return Resp_data;
    }
}

