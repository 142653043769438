import React, { Suspense, useEffect, useState, lazy, Fragment, } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // new import

import Float from "./cubes/miscs/float.js";
import Footer from "./cubes/miscs/footer.js";
import Sound from "./cubes/sound/sound.js";
import Terms from "./cubes/terms/terms.js";

import { authenticate } from "./assets/js/authenticate.js";


import useSound from 'use-sound';
import mp3_button from "./assets/sounds/button_bloop.mp3";


const Landing = lazy(() => import("./cubes/landing/landing.js"));
// const Lobby = lazy(() => import("./cubes/lobby/lobby.js"));

const Mobile = lazy(() => import("./cubes/miscs/mobile.js"));
const E404 = lazy(() => import("./cubes/miscs/e404.js"));
const Play = lazy(() => import("./cubes/play/play.js"));
// const Backend1 = lazy(() => import("./cubes/app_game/main_game.js"));
// const URL_GET = lazy(() => import("./cubes/miscs/url_get.js"));

export default function App() {

  //>> OPTION: SOUND, default = True
  const [Audio, set_Audio] = useState(false);
  const [bt_click] = useSound(mp3_button, { volume: 0.25 });

  // ########## THIS PART BE REPLACE WITH MIN-WIDTH and MIN-HEIGHT CONDITIONS
  // Verify if its mobile or not
  const [Portrait, set_Portrait] = useState(window.matchMedia("(orientation: portrait)").matches);
  useEffect(() => {
    const handleOrientation = () => {
      set_Portrait(window.matchMedia("(orientation: portrait)").matches)
    }
    window.addEventListener("resize", handleOrientation);
    // console.log('hand_Orientation: ' + Portrait)
  });


  const [TOS, setTOS] = useState(false);
  const [FirstTime, set_FirstTime] = useState(true);

  // Run once to check if session exists, if not create a new session in cookies and log it in history
  useEffect(() => {
    const run_start = async () => {
      const resp = await authenticate();
      if (resp['message'] !== 'welcome') {
        set_FirstTime(false);
      }
    }
    run_start();

    var pathArray = window.location.pathname.split('/@');
    if (pathArray.length === 2 && pathArray[1].length === 6) {
      localStorage.clear();
      localStorage.setItem("room_code", pathArray[1]);
      window.location = '/play';
    }
  }, [])

  // if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  //   // document.documentElement.addEventListener('click', function () {
  //   //   var el = document.documentElement;

  //   //   var rfs = el.requestFullscreen || el.webkitRequestFullscreen || el.mozRequestFullScreen || el.msRequestFullscreen;

  //   //   if (rfs) {
  //   //     rfs.call(el);
  //   //   }
  //   // });
  // }


  return (
    < BrowserRouter >

      <Suspense fallback={<Fragment />}>

        {Portrait ? <Mobile /> :

          <Routes>
            <Route path="/" element={<Landing Portrait={Portrait} bt_click={bt_click} Audio={Audio} FirstTime={FirstTime} set_FirstTime={set_FirstTime} />} />
            <Route path="/main" element={<Landing menu='main' bt_click={bt_click} Audio={Audio} FirstTime={FirstTime} set_FirstTime={set_FirstTime} />} />
            <Route path="/play" element={<Play bt_click={bt_click} Audio={Audio} />} />
            {/* <Route path="/@:game_id" element={<URL_GET />} /> */}

            {/* <Route path="/test0" element={<Lobby test_mode={true} />} /> */}
            {/* <Route path="/test1" element={<Backend1 RoomStatus={'placement'} test={true} />} /> */}
            {/* <Route path="/test2" element={<Backend1 RoomStatus={'playing'} test={true} />} /> */}
            {/* <Route path="/test3" element={<Backend1 RoomStatus={'scoreboard'} test={true} />} /> */}
            <Route path="*" element={<E404 />} />
          </Routes>
        }
      </Suspense>


      <Sound Audio={Audio} set_Audio={set_Audio} />
      <Float />
      <Footer setTOS={setTOS} />
      {TOS ? <Terms setTOS={setTOS} /> : <Fragment />}

    </BrowserRouter >
  )
}
