import React from "react";
import "./float.scss";


export default function float() {

    // const snowContent = ['&#10052', '&#10053', '&#10054']
    const snowContent = ['❅', '❄',];
    const nSnowflake = 10;


    var snowArray = Array.from(Array(nSnowflake + 1), (rows, i) => {

        return (
            <li className="circles" key={i}>{snowContent[i % 3]}</li>
        )
    });


    return (

        <div className="float">
            <ul>
                {snowArray}
            </ul>

        </div>
    );

}