import React from "react";
import { createRoot } from "react-dom/client";

import "./index.scss";
import "./assets/style.scss";
import './assets/fontawesome/css/all.css';
import './assets/fontawesome/css/fontawesome_6.0.css';

import App from './App.js';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <App />
);

