import React from 'react';
import './footer.scss';


const current = new Date();
const date = `${current.getFullYear()}`;

function Copyright({ setTOS }) {

    function open_terms() {
        setTOS(true);
    }

    return (
        < footer className='flexcol' id="footer-scroll">
            <div className='copyright'>
                <div className='terms' onClick={() => open_terms()}>
                    <div className='superscript smaller'>1</div> Terms of service
                </div>
                Adelie Throne © {date}
            </div>
        </footer >
    )
}

export default Copyright;
